import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import "../../Pages/Header/header.css";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
export default function MegaMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState("Profile");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemHover = (text) => {
    setHoveredMenuItem(text);
  };

  return (
    <div className="menu_button">
      <p
        className="menu_button_li m-0"
        id="mega-menu"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {props?.name}
        {props?.submenu?.length > 0 && (
          <ArrowDropDownOutlinedIcon htmlColor="black" />
        )}
      </p>
      <Menu
        id="mega-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <div className="megamenu_drpdwn">
          <Col className="mega_drop_col">
            <Row className="justify-content-center pb-3">
              {props?.submenu?.map((menu, i) => {
                return (
                  <MenuItem
                    key={menu + i}
                    className={
                      hoveredMenuItem === menu
                        ? "mega_drop_menuitem active_btn_menu_mega"
                        : "mega_drop_menuitem"
                    }
                    onClick={handleClose}
                    onMouseOver={() => handleMenuItemHover(menu)}
                  >
                    <Link
                      className="text-dark decoration-none"
                      to={`/collection/${menu
                        ?.split(" ")
                        .join("-")
                        .toLowerCase()}`}
                    >
                      {menu}
                    </Link>
                  </MenuItem>
                );
              })}
            </Row>
          </Col>
          <Col className="mega_drop_col_img">
            <img
              src={
                hoveredMenuItem === "Ajanta cave"
                  ? "https://lh3.googleusercontent.com/p/AF1QipObxgW07uYhWDJwAaGocI3q45EwOtXbgq7RfMwH=s1360-w1360-h1020"
                  : hoveredMenuItem === "Sightseeing"
                  ? "https://lh3.googleusercontent.com/p/AF1QipOGpL0sFuuMevErR-C_j_K5B5iF-4cj0M6NOvad=s1360-w1360-h1020"
                  : "https://lh3.googleusercontent.com/p/AF1QipPGr8bq4X3D3qL1bSjMWkTBhIuI47ps3W5ow0XX=s1360-w1360-h1020"
              }
              alt="menu_itm"
            />
            <Link
              className="text-dark decoration-none"
              to={`/collection/${hoveredMenuItem
                ?.split(" ")
                .join("-")
                .toLowerCase()}`}
            >
              <button className="mega_drop_btn_img">{hoveredMenuItem}</button>
            </Link>
          </Col>
        </div>
      </Menu>
    </div>
  );
}
