import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.css";
function FooterMenu(props) {
  return (
    <Col md="3" xs="6" className=" text-white">
      <p className="title_menu_footer_title mb-2">{props?.title}</p>

      {props?.menu?.map((item) => (
        <Link className="decoration-none" to={props?.policy === "true" ? "/policies" :""}>  <p
          onClick={() => {
            if (item === "Contact us") {
              window.open("/contact-us", "_self");
            }
            if (item === "+91 8210773776") {
              window.open("tel:+918210773776");
            }
            if(props?.policy === "true"){
              props?.setActivePolicy(item)
            }
          }}
          key={item}
          className="title_menu_footer_li"
        >
          {item}
        </p>
        </Link>
      
      ))}
    </Col>
  );
}

export default FooterMenu;
