import React, { useState } from "react";
import "./topdestination.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function TopDestinationCard(props) {
  return (
    <div
      className="topdest-card mb-3"
      style={{
        height: props?.height ?? "100%",
      }}
    >
      {props?.tag === true && (
        <div className="ribbon-wrapper">
          <div className="ribbon">{props?.tagname}</div>
        </div>
      )}
      <Link to={`/tour/${props?.subtitle?.split(" ").join("-").toLowerCase()}`}>
        <img
          effect="blur"
          className="img_top_card"
          style={{
            // objectFit: "cover",
            height: "100%",
            width: window.innerWidth < 600 ? "100%" : "auto",
          }}
          src={props?.src}
          alt="image"
        />
      </Link>

      <div className="overlay-text-topdest">
        <h6 className="topdest-card-title truncate">{props?.title}</h6>
        <p className="topdest-sub-title">{props?.subtitle}</p>
      </div>
    </div>
  );
}
export default TopDestinationCard;
