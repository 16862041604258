import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import CollectionCard from "./CollectionCard";
//Context
import { AppContext } from "../../contexts/AppContext";
import products from "../../Config/configuration.json";
import { useLocation } from "react-router-dom";
function Collections() {
  //Context
  const { activeCollection, setActiveCollection } = useContext(AppContext);
  let location = useLocation();

  useEffect(() => {
    if (window.location.href.includes("collection")) {
      if (window.location.pathname.split("/")[2]?.includes("all-tours")) {
        setActiveCollection(products.Collections);
      } else if (
        window.location.pathname.split("/")[2]?.includes("best-selling")
      ) {
        const allProducts = products.Collections.filter(
          (products) => products?.["best-selling"] === true
        );
        setActiveCollection(allProducts);
      } else {
        const allProducts = products.Collections.filter(
          (products) =>
            products?.category.toLowerCase() ===
            window.location.pathname.split("/")[2]?.split("-").join(" ")
        );
        setActiveCollection(allProducts);
      }
    }
  }, [location?.pathname, setActiveCollection]);

  return (
    <>
      <div className="collection_wrapper pt-5 mt-5 bg-light">
        <Col>
          <h3 className="home-title">
            {window.location.pathname.split("/")[2].split("-").join(" ")}
          </h3>
        </Col>
        <Row className="mt-3">
          {activeCollection?.length > 0 ? (
            <CollectionCard activeCollection={activeCollection} />
          ) : (
            <Row className="text-center py-5 my-5 collection-card-title">
              <h4>No active collection</h4>
            </Row>
          )}
        </Row>
      </div>
    </>
  );
}

export default Collections;
