import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Row } from "reactstrap";
import "./contactus.css";
const ContactUs = () => {
  const [state, handleSubmit] = useForm("xgejaykk");
  if (state.succeeded) {
    return (
      <Row
        className="thanks_row"
        style={{
          width: "100%",
          height: "50vh",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <h3>Thanks for joining!</h3>
      </Row>
    );
  }

  return (
    <div style={styles.wrapper_contact}>
      <h4 style={styles.heading}>Contact us</h4>
      <form style={styles.form} onSubmit={handleSubmit}>
        <label htmlFor="email">Email Address</label>
        <input style={styles.input} id="email" type="email" name="email" />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <label htmlFor="location">Location</label>
        <input style={styles.input} id="location" name="location" />
        <label htmlFor="text">Querry</label>
        <textarea style={styles.input} id="querry" name="querry" />
        <ValidationError prefix="querry" field="querry" errors={state.errors} />
        <button style={styles.button} type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </div>
  );
};

const styles = {
  wrapper_contact: {
    backgroundColor: "#ffe700",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    margin: "7rem auto auto auto",
    fontFamily: "Poppins",
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "10px",
    margin: "5px 0",
    borderRadius: "5px",
    border: "none",
  },
  button: {
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
  },
};

export default ContactUs;
