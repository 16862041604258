import React from "react";
import { Row, Col } from "reactstrap";
import "./blogs.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../index.css";
import BlogCard from "./BlogCard";
import blogs from "../../Config/bogsConfig.json";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <>
      <Row className="container m-auto mt-4">
        <Col lg="12">
          <Row className="py-2">
            <Col>
              <h3 className="home-title">
                Blogs
                <span className="dot_icon">.</span>
              </h3>
            </Col>
            <Col className="text-align-end desktop_only">
              <div className="d-flex w-100 justify-content-end ">
                <Link
                  className="decoration-none d-flex w-100 justify-content-end text-dark"
                  to={`/blogs`}
                >
                  <h4 className="home-subtitle cursor-pointer">All Blogs</h4>
                  <ArrowForwardIcon className="cursor-pointer" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="blogcards-wrapper">
            {blogs?.Blogs?.map((blog) => (
              <Col
                md="3"
                xs="8"
                key={blog?.id + "ss"}
                className="d-flex justify-content-center px-2"
              >
                <BlogCard Blog={blog} />
              </Col>
            ))}
          </Row>
          <Col className="mob_only py-2">
            <div className="d-flex w-100 justify-content-center ">
              <Link
                className="decoration-none d-flex w-100 justify-content-center text-dark"
                to={`/blogs`}
              >
                <h4 className="home-subtitle cursor-pointer">All Blogs</h4>
                <ArrowForwardIcon className="cursor-pointer" />
              </Link>
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default Blogs;
