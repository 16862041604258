import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordion.css";
import { Link } from "react-router-dom";
export default function MenuAccordion({
  menus,
  heading,
  policies,
  setActivePolicy,
  handleDrawer,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="pb-5">
      <h5 className="prdct_subheader pb-3">{heading ?? ""}</h5>
      {menus?.length > 0 &&
        menus?.map((item, i) => (
          <Accordion
            className="accordian_header_menu"
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Link className="decoration-none text-dark" to={item?.link}>
                <Typography
                  className="accordian_title_menu"
                  sx={{ width: "100%", flexShrink: 0 }}
                >
                  {item?.category}
                </Typography>
              </Link>
            </AccordionSummary>
            {item?.tours?.map((item, i) => (
              <AccordionDetails
                key={item?.text + i}
                className="accordian_body_menu"
              >
                <Link
                  className="decoration-none"
                  to={item?.link}
                  onClick={handleDrawer}
                >
                  <Typography
                    className="accordian_subtitle_menu"
                    onClick={() => {
                      handleDrawer();
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Link>
              </AccordionDetails>
            ))}
          </Accordion>
        ))}
    </div>
  );
}
