import React, { useContext } from "react";
import { Modal } from "reactstrap";
//context
import { AppContext } from "../../contexts/AppContext";
import GalleryCarousel from "../Main carousel/GalleryCarousel";
function ImageViewer() {
  const { toggleGallery, isOpenGallery } = useContext(AppContext);

  const imagesSliderMain = [
    "https://images.pexels.com/photos/6522109/pexels-photo-6522109.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Replace with the actual image file paths or URLs
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHomepage%2FBHS---Website-Banner-1689230381735.jpg&w=3840&q=100",
    "https://lh3.googleusercontent.com/p/AF1QipNrUfG0iUm9uCBLuRaxuPnLHHZBwQzXzOzc8i08=s3840-w3840-h1990-rw",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHomepage%2FTHC-Banner---Website-%20July%2012th-1689230416687.jpg&w=3840&q=100",
  ];
  return (
    <>
      <Modal
        className="gallery_modal"
        contentClassName="content_gallery_modal"
        isOpen={isOpenGallery}
        toggle={toggleGallery}
        fullscreen={true}
      >
        <GalleryCarousel galleryView={true} images={imagesSliderMain} />
      </Modal>
    </>
  );
}

export default ImageViewer;
