import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./featurehotel.css"; // Create a CSS file for styling
import FeaturedCard from "../Featured Card/FeaturedCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../index.css";
import { AppContext } from "../../contexts/AppContext";
import { Link } from "react-router-dom";
import products from "../../Config/configuration.json";

const FeaturedHotel = () => {
  const { featuredTours, setFeaturedTours } = useContext(AppContext);

  useEffect(() => {
    const allProducts = products?.Collections.filter(
      (products) => products?.featured === true
    );
    setFeaturedTours(allProducts);
    // }
  }, [setFeaturedTours]);

  return (
    <>
      <Row className="container m-auto mt-4">
        <Col lg="12">
          <Row className="py-2">
            <Col>
              <h3 className="home-title">
                FEATURED TOURS
                <span className="dot_icon">.</span>
              </h3>
            </Col>
            <Col className="text-align-end desktop_only">
              <Link
                className="d-flex w-100 justify-content-end text-dark decoration-none"
                to={`/collection/all-tours`}
              >
                <h4 className="home-subtitle ">All Tours</h4>
                <ArrowForwardIcon className="" />
              </Link>
            </Col>
          </Row>
          <Row className="featuredcard-wrapper">
            {featuredTours?.map((tour) => (
              <FeaturedCard
                featuredTours={featuredTours}
                title="Memorable Mumbai"
                name={tour?.name}
                src={tour?.images[0]}
              />
            ))}
          </Row>
          <Col className="mob_only py-2">
            <Link
              className="d-flex w-100 justify-content-center text-dark"
              to="/collection/all-tours"
            >
              <h4 className="home-subtitle cursor-pointer">All Tours</h4>
              <ArrowForwardIcon className="cursor-pointer" />
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default FeaturedHotel;
