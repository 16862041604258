import React, { useContext, useEffect, useState } from "react";
import "./drawer.css";
import products from "../../Config/configuration.json";
//Context
import { AppContext } from "../../contexts/AppContext";
import MenuAccordion from "../Accordian/MenuAccordion";

function Drawer() {
  const { handleDrawer, isOpenDrawer } = useContext(AppContext);
  const [drawerMenu, setDrawerMenu] = useState();

  useEffect(() => {
    const uniqueCategories = [
      ...new Set(products.Collections.map((product) => product.category)),
    ];

    // Use map() to create an array of objects with productss grouped by category
    const productsByCategory = uniqueCategories.map((category) => ({
      category,
      link: `/collection/${category?.split(" ").join("-").toLowerCase()}`,
      tours: products.Collections.filter(
        (product) => product.category === category
      ).map(({ id, name, link }) => ({
        id,
        name,
        link: `/tour/${name.split(" ").join("-").toLowerCase()}`,
      })),
    }));
    setDrawerMenu(productsByCategory);
  }, []);

  return (
    <>
      <div
        className="drawer_wrapper"
        style={{
          left: isOpenDrawer
            ? 0
            : window.innerWidth > 1300
            ? "-2000px"
            : "-1000px",
        }}
      >
        <MenuAccordion menus={drawerMenu} handleDrawer={handleDrawer} />
      </div>
      <div
        style={{
          display: isOpenDrawer ? "block" : "none",
        }}
        className="backdrop_faded"
        onClick={handleDrawer}
      ></div>
    </>
  );
}

export default Drawer;
