import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import Accordion from "../Accordian/Accordion";
import "./policies.css";

import { AppContext } from "../../contexts/AppContext";
function Policies() {
  //context
  const { activePolicy, setActivePolicy } = useContext(AppContext);
  const [activePolicyContents, setActivePolicyContents] = useState("");
  const policies = [
    {
      name: "Terms and Conditions",
      Description: [
        {
          id: 1,
          text: "Terms and conditions",
        },
        {
          id: 2,
          text: "Information We Collect",
        },
        {
          id: 3,
          text: "How We Use Your Information",
        },
        {
          id: 4,
          text: "Information Sharing",
        },
        {
          id: 5,
          text: "Data Retention",
        },
      ],
    },
    {
      name: "Privacy Policy",
      Description: [
        {
          id: 1,
          text: "Terms and conditions",
        },
        {
          id: 2,
          text: "Information We Collect",
        },
        {
          id: 3,
          text: "How We Use Your Information",
        },
        {
          id: 4,
          text: "Information Sharing",
        },
        {
          id: 5,
          text: "Data Retention",
        },
      ],
    },
  ];
  const PrivacyPolicyContent =
    "<h3>Privacy Policy</h3> <p>This privacy policy outlines how we collect, use, and protect your personal information.</p> <h4>Information We Collect</h4> <p>We collect information you provide directly to us, such as your name, email address, phone number, billing information, and any other information you submit via forms on our website. We also automatically collect information about your visit like your IP address, browser type, operating system, referral URLs, device information, and pages visited.</p> <h4>How We Use Your Information</h4> <p>We use the information we collect to:</p> <ul> <li>Provide and maintain our services</li> <li>Respond to your requests and inquiries</li> <li>Send you promotional and marketing emails (you can opt-out anytime)</li> <li>Improve user experience and site performance</li> <li>Prevent and detect security threats, fraud or other malicious activity</li> </ul> <h4>Information Sharing</h4> <p>We do not rent, sell or share your personal information with third-parties except as disclosed within this policy.</p> <h4>Data Retention</h4> <p>We retain your personal information for as long as necessary to provide our services, and as needed to comply with our legal obligations, resolve disputes, and enforce our policies.</p> <h4>Security</h4> <p>We implement appropriate technical and organizational measures to protect your information against unauthorized or unlawful processing and against accidental loss, destruction or damage.</p> <h4>Changes to This Policy</h4> <p>We may occasionally update this policy. We encourage you to review this policy periodically.</p> <h4>Contact Us</h4> <p>If you have any questions about this privacy policy or how we handle your personal information, please contact us at [email address].</p>";

  const termsContents =
    "<h3>Terms and conditions</h3> <p>This privacy policy outlines how we collect, use, and protect your personal information.</p> <h4>Information We Collect</h4> <p>We collect information you provide directly to us, such as your name, email address, phone number, billing information, and any other information you submit via forms on our website. We also automatically collect information about your visit like your IP address, browser type, operating system, referral URLs, device information, and pages visited.</p> <h4>How We Use Your Information</h4> <p>We use the information we collect to:</p> <ul> <li>Provide and maintain our services</li> <li>Respond to your requests and inquiries</li> <li>Send you promotional and marketing emails (you can opt-out anytime)</li> <li>Improve user experience and site performance</li> <li>Prevent and detect security threats, fraud or other malicious activity</li> </ul> <h4>Information Sharing</h4> <p>We do not rent, sell or share your personal information with third-parties except as disclosed within this policy.</p> <h4>Data Retention</h4> <p>We retain your personal information for as long as necessary to provide our services, and as needed to comply with our legal obligations, resolve disputes, and enforce our policies.</p> <h4>Security</h4> <p>We implement appropriate technical and organizational measures to protect your information against unauthorized or unlawful processing and against accidental loss, destruction or damage.</p> <h4>Changes to This Policy</h4> <p>We may occasionally update this policy. We encourage you to review this policy periodically.</p> <h4>Contact Us</h4> <p>If you have any questions about this privacy policy or how we handle your personal information, please contact us at [email address].</p>";

  useEffect(() => {
    // Parse the HTML content as a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      activePolicy === "Terms and Conditions"
        ? termsContents
        : PrivacyPolicyContent,
      "text/html"
    );

    // Get all h3 and h4 elements
    const headings = [...doc.querySelectorAll("h3, h4")];

    // Add unique IDs to each heading
    headings.forEach((heading, index) => {
      heading.setAttribute("id", (index + 1).toString());
    });

    // Convert the modified HTML back to a string
    const modifiedTermsContents = new XMLSerializer().serializeToString(doc);
    setActivePolicyContents(modifiedTermsContents);
    // Now you can use modifiedTermsContents as your HTML content with added IDs
  }, [termsContents, activePolicy]);

  return (
    <div className="container mt-4 pt-5">
      <Col>
        <h2 className="policies_heading w-100">{activePolicy}</h2>
        <Row>
          <Col md="3">
            <div className="ploicies_menu_wrapper">
              <Accordion
                highlights={policies}
                policies={true}
                setActivePolicy={setActivePolicy}
              />
            </div>
          </Col>
          <Col md="9">
            <div
              className="policiy_para_container"
              dangerouslySetInnerHTML={{
                __html: activePolicyContents,
              }}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}

export default Policies;
