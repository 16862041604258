import React, { useContext } from "react";
import "./product.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CollectionsIcon from "@mui/icons-material/Collections";
//context
import { AppContext } from "../../contexts/AppContext";
function ProductCard(props) {
  const { toggleImageView } = useContext(AppContext);
  return (
    <div
      className={
        props?.mainImage ? "product-card-main mb-3" : "product-card mb-3"
      }
      style={{
        height: props?.height ?? "100%",
      }}
    >
      <div className="card-content">
        <LazyLoadImage
          effect="blur"
          className={
            props?.mainImage === true
              ? "img_top_card_prod_main"
              : "img_top_card_prod"
          }
          style={{
            objectFit: "cover",
          }}
          src={props?.src}
          alt="image"
        />
      </div>
      {props?.mainImage === true ? (
        <span className="img_icon_prod" onClick={toggleImageView}>
          <CollectionsIcon fontSize="sm" />
        </span>
      ) : null}
    </div>
  );
}
export default ProductCard;
