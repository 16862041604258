import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./accordion.css";
export default function FaqAccordion({ faq, heading }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  return (
    <div className="py-5">
      <h5 className="prdct_subheader pb-3">{heading ?? ""}</h5>
      {faq?.length > 0 &&
        faq?.map((item, i) => (
          <Accordion
            key={item?.question}
            className="faq_acc_main"
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary
              className="faq_acc "
              aria-controls="panel1d-content"
            >
              <div className="d-flex align-items-center faq_icon_div">
                {expanded === i ? (
                  <RemoveIcon sx={{ fontSize: "1.3rem", color: "black" }} />
                ) : (
                  <AddIcon sx={{ fontSize: "1.3rem", color: "black" }} />
                )}
              </div>
              <Typography className="faq_acc_content">
                {item?.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="faq_acc_deatail">
              <Typography>{item?.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
