import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import "./bottombar.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SnowshoeingOutlinedIcon from "@mui/icons-material/SnowshoeingOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Link } from "react-router-dom";
function BottomBar() {
  const [activeTab, setActiveTab] = useState("Home"); //for bottom mobile navigation

  useEffect(() => {
    let path = window.location.pathname;
    if (path.includes("Sightseeing")) {
      setActiveTab("Sightseeing");
    } else if (path.includes("all-tours")) {
      setActiveTab("Tours");
    } else {
      setActiveTab("Home");
    }
  }, []);

  const bottomMenu = [
    {
      title: "Home",
      icon: (
        <HomeOutlinedIcon
          sx={{
            fontSize: "25px",
            color: activeTab === "Home" ? "black" : "#808080",
          }}
        />
      ),
      link: "/",
    },
    {
      title: "Sightseeing",
      icon: (
        <SnowshoeingOutlinedIcon
          sx={{
            fontSize: "25px",
            color: activeTab === "Sightseeing" ? "black" : "#808080",
          }}
        />
      ),
      link: "/collection/Sightseeing",
    },
    {
      title: "Tours",
      icon: (
        <PlaceOutlinedIcon
          sx={{
            fontSize: "25px",
            color: activeTab === "Tours" ? "black" : "#808080",
          }}
        />
      ),
      link: "/collection/all-tours",
    },
    {
      title: "Account",
      icon: (
        <PersonOutlineOutlinedIcon
          sx={{
            fontSize: "25px",
            color: activeTab === "Account" ? "black" : "#808080",
          }}
        />
      ),
      link: "/myaccount",
    },
  ];
  return (
    <>
      <Row className="bottombar_wrapper mob_only">
        {bottomMenu?.map((item) => (
          <Link
            onClick={() => setActiveTab(item?.title)}
            className="bottm_menu_wrapper decoration-none"
            to={item?.link?.toLowerCase()}
          >
            <Col key={item?.title} className="bottm_menu_wrapper p-0">
              {item?.icon}
              <p
                className="bottm_menu_li"
                style={{
                  color: activeTab === item?.title ? "black" : "grey",
                }}
              >
                {item?.title}
              </p>
            </Col>
          </Link>
        ))}
      </Row>
    </>
  );
}

export default BottomBar;
