import React, { useContext } from "react";
import { Row, ModalHeader } from "reactstrap";
import ImageViewerCard from "./GalleryViewerCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CollectionsIcon from "@mui/icons-material/Collections";
//Context
import { AppContext } from "../../contexts/AppContext";
function GalleryView() {
  const { toggleImageView } = useContext(AppContext);
  const allimages = [
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
    "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20-%20Artist%20House-1690523889778.jpg&w=828&q=75",
  ];
  return (
    <div>
      <ModalHeader className="header_gall">
        <Row>
          <div className="back_icon_gall">
            <ChevronLeftIcon onClick={toggleImageView} color="black" />
          </div>
          <div className="btn-img-gall-back">
            <CollectionsIcon fontSize="sm" /> <p className="m-0">Photos</p>
          </div>
        </Row>
      </ModalHeader>
      <Row className="mx-2">
        <ImageViewerCard allimages={allimages} />
      </Row>
    </div>
  );
}

export default GalleryView;
