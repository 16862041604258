import React from "react";
import "./featuredcard.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
function FeaturedCard(props) {
  return (
    <>
      <Col className="d-flex justify-content-center px-1 py-2">
        <Link to={`/tour/${props?.name?.split(" ").join("-").toLowerCase()}`}>
          <div className="featured-card">
            {props?.tag === true && (
              <div className="ribbon-wrapper">
                <div className="ribbon">{props?.tagname}</div>
              </div>
            )}
            <img effect="blur" height="inherit" src={props?.src} alt="image" />

            <div className="overlay-text-featured">
              <h6 className="featured-card-title truncate">{props?.title}</h6>
              <p className="featured-sub-title">{props?.name}</p>
            </div>
          </div>
        </Link>
      </Col>
    </>
  );
}
export default FeaturedCard;
