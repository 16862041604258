import React from "react";
import "./blogs.css";
import "../../index.css";
import { Link } from "react-router-dom";

function BlogCard({ Blog }) {
  const blogDuration = (duration) => {
    let value = Number(duration / 60);
    let roundOff = Math.round(value);
    return roundOff;
  };

  return (
    <>
      <div className="blog-card">
        <Link className="decoration-none" to={Blog.link}>
          <span className="overlay-read-blog">
            {blogDuration(Blog?.duration)} mins read
          </span>
          <div className="card-content">
            <img
              src={Blog?.properties?.images?.[0]}
              alt="cover"
              style={{
                objectFit: "cover",
                height: "300px",
              }}
            />
          </div>

          <p className="overlay-text-blog">
            <h6 className="best-selling-card-title">{Blog?.title}</h6>
            <p className="blog-subtitle pt-2">{Blog?.shortDescription}</p>
          </p>
        </Link>
      </div>
    </>
  );
}

export default BlogCard;
