import React, { useContext } from "react";
import { Col } from "reactstrap";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "../Collections/collection.css";
//context
import { AppContext } from "../../contexts/AppContext";
import ImageViewer from "./ImageViewer";
function GalleryViewCard({ allimages }) {
  const { toggleGallery, isOpenGallery } = useContext(AppContext);
  return (
    <>
      {allimages?.map((item, i) => (
        <Col
          key={item + "dd"}
          lg="2"
          md="4"
          xs="6"
          className="d-flex justify-content-center px-1 py-2 mb-3"
        >
          <div className="collection-card">
            <div className="card-content">
              <LazyLoadImage
                onClick={toggleGallery}
                effect="blur"
                width="100%"
                src={item}
                alt="image"
              />
            </div>
          </div>
        </Col>
      ))}
      {isOpenGallery && <ImageViewer />}
    </>
  );
}

export default GalleryViewCard;
