import React from "react";
import { Row, Col } from "reactstrap";
import "./ethics.css";
import "../../index.css";
import PlaceIcon from "@mui/icons-material/Place";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import BadgeIcon from "@mui/icons-material/Badge";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
const Ethics = () => {
  const ethics = [
    {
      title:
        "See the real India! Our tours take you on authentic experiences around Delhi, Mumbai, Kerala, Rajasthan and more",
      src: "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2Fhostel%2Fimages%2FKufri.jpg%2FKufri-1672401788380.jpg&w=1080&q=75",
      link: "",
      icon: (
        <PlaceIcon
          sx={{
            fontSize: "5rem",
            color: "#ffe700",
          }}
        />
      ),
    },
    {
      title:
        "Our profits go back to the community through our NGO Reality Gives",
      src: "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2Fhostel%2Fimages%2FKufri.jpg%2FKufri-1672401788380.jpg&w=1080&q=75",
      link: "",
      icon: (
        <VolunteerActivismIcon
          sx={{
            fontSize: "5rem",
            color: "#ffe700",
          }}
        />
      ),
    },
    {
      title:
        "Our expert guides come from the communities we work in based in Dharavi (Mumbai) and Sanjay Colony (New Delhi)",
      src: "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2Fhostel%2Fimages%2FKufri.jpg%2FKufri-1672401788380.jpg&w=1080&q=75",
      link: "",
      icon: (
        <BadgeIcon
          sx={{
            fontSize: "5rem",
            color: "#ffe700",
          }}
        />
      ),
    },
    {
      title:
        "Our work supports Reality Gives’ educational programs to help community members reach their potential.",
      src: "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2Fhostel%2Fimages%2FKufri.jpg%2FKufri-1672401788380.jpg&w=1080&q=75",
      link: "",
      icon: (
        <HomeWorkIcon
          sx={{
            fontSize: "5rem",
            color: "#ffe700",
          }}
        />
      ),
    },
  ];
  return (
    <>
      <Row className="container m-auto mt-4">
        <Col lg="12">
          <Row className="py-2">
            <Col>
              <h3 className="home-title">
                Our Ethics
                <span className="dot_icon">.</span>
              </h3>
            </Col>
          </Row>
          <Row className="ethics-wrapper">
            {ethics.map((item) => (
              <Col
                key={item?.title}
                className="d-flex justify-content-center px-2"
              >
                <div className="ethic-card">
                  <div className="text-center">
                    {/* <img
                      src={
                        item?.src ??
                        "https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2Fhostel%2Fimages%2FKufri.jpg%2FKufri-1672401788380.jpg&w=1080&q=75"
                      }
                      alt="image"
                      style={{
                        objectFit: "cover",
                        height: "300px",
                      }}
                    /> */}
                    {item?.icon}
                  </div>

                  <div className="overlay-text-blog">
                    {/* <h6 className="best-selling-card-title">
                      {item?.title ??
                        "20 Things To Cross Off Your Bucket List In Goa"}
                    </h6> */}
                    <p className="blog-subtitle">{item?.title}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Ethics;
