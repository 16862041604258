import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./topdestination.css"; // Create a CSS file for styling
import "./TopDestinationCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../index.css";
import TopDestinationCard from "./TopDestinationCard";
import { Link } from "react-router-dom";
import products from "../../Config/configuration.json";

import { AppContext } from "../../contexts/AppContext";
const TopDestination = () => {
  const { topSightSeeingTours, setTopSightSeeingTours } =
    useContext(AppContext);

  useEffect(() => {
    const allProducts = products?.Collections.filter(
      (products) => products?.category === "Sightseeing"
    );
    setTopSightSeeingTours(allProducts);
  }, []);
  return (
    <>
      <Row className="container m-auto mt-3">
        <Col lg="12">
          <Row className="py-2">
            <Col>
              <h3 className="home-title">
                TOP Sightseeing
                <span className="dot_icon">.</span>
              </h3>
            </Col>
            <Col className="text-align-end desktop_only">
              <Link
                className="decoration-none d-flex w-100 justify-content-end text-dark"
                to={`/collection/sightseeing`}
              >
                <h4 className="home-subtitle cursor-pointer">
                  All Sightseeing
                </h4>
                <ArrowForwardIcon className="cursor-pointer" />
              </Link>
            </Col>
          </Row>
          <Row className="topdest-wrapper">
            <Col className="px-2 mb-3" md="4">
              <TopDestinationCard
                height="610px"
                title="Memorable Mumbai"
                subtitle={topSightSeeingTours[0]?.name ?? "Nothing"}
                src={topSightSeeingTours[0]?.images[0]}
              />
            </Col>
            <Col className="px-2 " md="4">
              <TopDestinationCard
                height="300px"
                title="Memorable Mumbai"
                subtitle={topSightSeeingTours[1]?.name ?? "Nothing"}
                src={topSightSeeingTours[1]?.images[0]}
              />
              <TopDestinationCard
                height="300px"
                title="Memorable Mumbai"
                subtitle={topSightSeeingTours[2]?.name ?? "Nothing"}
                src={topSightSeeingTours[2]?.images[0]}
              />
            </Col>{" "}
            <Col className="px-2 " md="4">
              <TopDestinationCard
                height="300px"
                title="Memorable Mumbai"
                subtitle={topSightSeeingTours[3]?.name ?? "Nothing"}
                src={topSightSeeingTours[3]?.images[0]}
              />
              <Row
                style={{
                  justifyContent: "center",
                  padding: "0px 8px 0px 9px",
                }}
              >
                <Col className="dest-card-small-wrapper p-0" md="6">
                  <TopDestinationCard
                    height="300px"
                    title="Memorable Mumbai"
                    subtitle={topSightSeeingTours[4]?.name ?? "Nothing"}
                    src={topSightSeeingTours[4]?.images[0]}
                  />{" "}
                </Col>
                <Col className="dest-card-small-wrapper p-0" md="6">
                  <TopDestinationCard
                    height="300px"
                    title="Memorable Mumbai"
                    subtitle={topSightSeeingTours[5]?.name ?? "Nothing"}
                    src={topSightSeeingTours[5]?.images[0]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Col className="mob_only py-2">
            <Link
              className="decoration-none d-flex w-100 justify-content-center text-dark"
              to={`/collection/sightseeing`}
            >
              <h4 className="home-subtitle cursor-pointer">All Sightseeing</h4>
              <ArrowForwardIcon className="cursor-pointer" />
            </Link>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default TopDestination;
