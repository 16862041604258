import React, { useState, useEffect } from "react";
import "./carousel.css";

const ImageSlider = () => {
  const images = [
    "https://lh3.googleusercontent.com/p/AF1QipNrUfG0iUm9uCBLuRaxuPnLHHZBwQzXzOzc8i08=s3840-w3840-h1990-rw", // Replace with the actual image file paths or URLs
    "https://lh3.googleusercontent.com/p/AF1QipMMxvBtPnxoEXnZYIQjuXzK1fMFIvdrmJpRa-Fm=s3840-w3840-h1990-rw",
    "https://lh3.googleusercontent.com/p/AF1QipMu2r5Mh-OEqp3ufFb4Ex71W7-q4feEo9Uvb6D5=s3840-w3840-h1990-rw",
    "https://lh3.googleusercontent.com/p/AF1QipPp7JQ8HkFkbWfG4UNGU56nqjTNnU1HKsFAN1OX=s3840-w3840-h1990-rw",
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleAutoScroll = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(handleAutoScroll, 3000); // Reduced the time for demonstration purposes

    return () => clearInterval(interval);
  }, []);

  const handleNavClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
            style={{
              backgroundImage: `url(${image})`,
              transform: `translateX(-${currentSlide * 100}%)`, // Move to the current slide
            }}
          />
        ))}
      </div>
      <div className="dots">
        {images.map((image, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => handleNavClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
