import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import "./footer.css";
import instalogo from "../../Assests/instagram.svg";
import fblogo from "../../Assests/facebook.svg";
import linkedinlogo from "../../Assests/linkedln.svg";
import FooterMenu from "./FooterMenu";
import footerlogo from "../../Assests/Memorable Mumbaiwhite.png";
import { AppContext } from "../../contexts/AppContext";
function Footer() {
  const { setActivePolicy } = useContext(AppContext);
  const Activities = ["Workations", "Group Bookings"];
  const importantLinks = ["Career", "About Us", "Press Mention"];
  const policies = ["Privacy Policy", "Terms and Conditions"];

  const contactDetails = [
    "Support Center",
    "Contact us",
    `+91 8210773776`,
    "(24 x 7) on Whatsapp",
    "contact@memorablemumbai.com",
  ];

  return (
    <Row className="footer mx-0">
      <Row className="footer_header">
        <div className="container align-items-center">
          <Col>
            <img className="footer_logo" src={footerlogo} alt="logo" />
          </Col>
          <Col className="text-end">
            <Row className="justify-content-end">
              <div className="social_logo_footer">
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/aquibyazdani/"
                  target="_blank"
                >
                  <img width="30px" src={instalogo} alt="insta" />
                </a>
              </div>
              <div className="social_logo_footer">
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/aquibyazdani/"
                  target="_blank"
                >
                  <img width="30px" src={fblogo} alt="fb logo" />
                </a>
              </div>{" "}
              <div className="social_logo_footer">
                <a
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/aquibyazdani"
                  target="_blank"
                >
                  <img width="30px" src={linkedinlogo} alt="linkedin" />
                </a>
              </div>
            </Row>
          </Col>
        </div>
      </Row>
      <Row className="py-5 footer_container ">
        <FooterMenu title={"Activities"} menu={Activities} />
        <FooterMenu title={"Important links"} menu={importantLinks} />
        <FooterMenu
          policy="true"
          setActivePolicy={setActivePolicy}
          title={"Policies"}
          menu={policies}
        />
        <FooterMenu title={"Contact Details"} menu={contactDetails} />
      </Row>

      <div className="footer_bttm_footer text-white border-top ">
        <div className="footer_container">
          <Col md="6">
            <p>
              Memorable Mumbai © {new Date().getFullYear()} All rights reserved
            </p>
          </Col>
          <Col md="6" className="text-end">
            <p>
              Powered by{" "}
              <a
                rel="noreferrer"
                href="https://www.instagram.com/aquibyazdani/"
                target="_blank"
              >
                Aquib Yazdani
              </a>
            </p>
          </Col>
        </div>
      </div>
    </Row>
  );
}

export default Footer;
