import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Pages/Header/Header";
import HomePage from "./Pages/HomePage";
import Drawer from "./components/Drawer/Drawer";
import Footer from "./components/Footer/Footer";
import BottomBar from "./components/BottomBar/BottomBar";
import NotFound from "./components/404 Page/NotFound";
import ContactUs from "./components/Contact us/ContactUs";
import Collection from "./components/Collections/Collections";
import Product from "./components/Products/Product";
import Policies from "./components/Policies/Policies";
import BlogCollection from "./components/Blogs/BlogCollection";
import BlogView from "./components/Blogs/BlogView";
import Whatsapp from "./components/whatsapp/Whatsapp";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Drawer />
        <Whatsapp />
        <Routes>
          <Route exact path="/" element={<HomePage />}></Route>
          <Route exact path="/contact-us" element={<ContactUs />}></Route>
          <Route
            exact
            path="/collection/:name"
            element={<Collection />}
          ></Route>
          <Route exact path="/blogs" element={<BlogCollection />}></Route>
          <Route exact path="/tour/:name" element={<Product />}></Route>
          <Route exact path="/blog/:name" element={<BlogView />}></Route>
          <Route exact path="/policies" element={<Policies />}></Route>

          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
        <BottomBar />
      </Router>
    </>
  );
}

export default App;
