import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Modal } from "reactstrap";
import "./product.css";
import ProductCard from "./ProductCard";
import { AppContext } from "../../contexts/AppContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GalleryView from "../Image Viewer/GalleryView";
import { useLocation } from "react-router-dom";
import products from "../../Config/configuration.json";
import YouTube from "react-youtube";
import Accordion from "../Accordian/Accordion";
import FaqAccordion from "../Accordian/FaqAccordion";
import HashLoader from "react-spinners/HashLoader";

function Product() {
  //context
  const { openImageView, toggleImageView, activeProduct, setActiveProduct } =
    useContext(AppContext);
  let location = useLocation();

  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth > 1024 ? true : false
  );
  const [loading, setLoading] = useState(true);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const enjoyingFactors = [
    {
      title: "authentic inside",
      body: "Get an authentic inside look at real life in India's largest slum",
    },
    {
      title: "authentic outside",
      body: "Get an authentic inside look at real life in India's largest slum",
    },
    {
      title: "authentic ",
      body: "Get an authentic inside look at real life in India's largest slum",
    },
    {
      title: "authentic yes",
      body: "Get an authentic inside look at real life in India's largest slum",
    },
  ];

  useEffect(() => {
    if (window.location.href.includes("/tour/")) {
      const productName = window.location.pathname
        .split("/")[2]
        .split("-")
        .join(" ");

      // Use the .find() method to find the product by name
      const foundProduct = products?.Collections.find(
        (product) => product.name?.toLowerCase() === productName
      );
      setActiveProduct(foundProduct);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (activeProduct) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  });

  const opts = {
    height: "390px",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <>
      <div className="container productpage_container mt-5 pt-5">
        {!loading && activeProduct?.name.length > 0 ? (
          <Col className="p-0 d-flex col align-items-center">
            <Row className="product-wrapper">
              <Col className="px-2 mb-3" xs="12" lg="8">
                <ProductCard
                  height={window.innerWidth > 992 ? "650px" : "auto"}
                  mainImage={true}
                  title="Memorable Mumbai"
                  subtitle="Udaipur"
                  src="https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%20-%20Artist%20House-1690523877419.jpg&w=3840&q=75"
                />
              </Col>
              <Col className="px-2 product_secnd_col" lg="4">
                <ProductCard
                  height="205px"
                  title="Memorable Mumbai"
                  subtitle="Udaipur"
                  src="https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPizzeria%20Area%202%20-%20Artist%20House-1690523886122.jpg&w=3840&q=75"
                />
                <ProductCard
                  height="205px"
                  title="Memorable Mumbai"
                  subtitle="Udaipur"
                  src="https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20Side%20-%20Artist%20House-1690523894053.jpg&w=3840&q=75"
                />
                <ProductCard
                  height="205px"
                  title="Memorable Mumbai"
                  subtitle="Udaipur"
                  src="https://www.thehosteller.com/_next/image?url=https%3A%2F%2Fstatic.thehosteller.com%2FHostel%2Fthe-hosteller-artist-house%2C-udaipur%2FPool%20Side%20-%20Artist%20House-1690523894053.jpg&w=3840&q=75"
                />
              </Col>{" "}
            </Row>
            <Row className="w-100">
              <Col className="p-0" md="8" xs="12">
                <h4 className="tour_name_prdct">
                  {activeProduct?.name ?? ""}
                  <span className="dot_icon">.</span>
                </h4>
              </Col>
              <Col className="btn_view_option_wrapper " xs="12" md="4">
                <div className="btn_view_option_prdct">{"View Options"}</div>
              </Col>
            </Row>
            <Row className="py-3">
              <Col md="6" xs="12" className="p-2">
                <YouTube
                  videoId="ZcpaAzf74-M"
                  opts={opts}
                  onReady={onPlayerReady}
                />
              </Col>
              <Col md="6" xs="12" className="summary_prdct">
                <div
                  dangerouslySetInnerHTML={{
                    __html: isExpanded
                      ? activeProduct?.summary
                      : `${activeProduct?.summary.substring(0, 400)}...`,
                  }}
                />
                <p className="show_more_prodct" onClick={handleExpand}>
                  {!isExpanded ? "Show more " : "Show Less "}
                  {!isExpanded ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </p>
              </Col>
            </Row>

            {/* WHY YOU LOVE IT */}
            {/* <Row className="w-100 prdct_subheader_wrapper">
              <h5 className="prdct_subheader">WHY You will enjoy it</h5>
            </Row>
            <Row className="enjoyit_wrapper_main container">
              {enjoyingFactors?.map((factor) => (
                <Col
                  xs="5"
                  md="3"
                  key={factor?.title}
                  className="enjoyit_wrapper mb-2"
                >
                  <Row className="p-2">
                    <Col xs="6" md="4">
                      <img
                        width="100%"
                        src="https://static.thehosteller.com/the-hosteller-bangalore,-brigade-road/HOSTEL%7D/Silicon%20Valley-1687337818091.svg"
                      />
                    </Col>

                    <Col xs="6" md="8" className="enjoyit_header_wrapper p-0">
                      <h6 className="enjoyit_header">WHY You will enjoy it</h6>
                    </Col>
                  </Row>
                  <p className="enjoyit_para">
                    Lalbag, Cubbon Park or Lumbini - take your pick of lush
                    green parks to bring back that sense of peace in the city.
                  </p>
                </Col>
              ))}
            </Row> */}

            {/* Availablity starts */}
            <Row className="w-100 prdct_subheader_wrapper mt-4">
              <Col md="6" xs="12" className="p-0">
                {/* highlights starts */}
                {activeProduct?.Highlights?.length && (
                  <Accordion
                    highlights={activeProduct?.Highlights}
                    heading="Highlights"
                    policies={false}
                  />
                )}
                {activeProduct?.MeetingDetails?.map((item) => {
                  return (
                    <>
                      <h5 className="prdct_subheader pb-3">
                        {"Meeting Points / Map"}
                      </h5>
                      <p className="prdct_para_meet_body">{item?.shortInfo}</p>
                      {item?.MeetingPoints?.map((item) => (
                        <>
                          <h5 className="prdct_subtitle">{item?.title}</h5>
                          <li className="prdct_para_meet_body">
                            {item?.subtitle}
                          </li>
                          <li className="prdct_para_meet_body">
                            {item?.description}
                          </li>
                        </>
                      ))}

                      {item?.EndPoints?.map((item) => (
                        <>
                          <h5 className="prdct_subtitle pt-3">{item?.title}</h5>

                          <li
                            dangerouslySetInnerHTML={{
                              __html: item?.subtitle,
                            }}
                            className="prdct_para_meet_body"
                          />
                          <li
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                            className="prdct_para_meet_body"
                          />
                        </>
                      ))}
                    </>
                  );
                })}

                {/* Faq */}
                {activeProduct?.FAQs?.length && (
                  <FaqAccordion faq={activeProduct?.FAQs} heading="FAQs" />
                )}

                {/* <GoogleMapProduct /> */}
              </Col>
            </Row>
          </Col>
        ) : (
          <Row className="text-center py-5 my-5 collection-card-title">
            {/* <h4>No such product</h4> */}
            <HashLoader loading={loading} color="#ffe700" />
          </Row>
        )}
      </div>
      <Modal
        className="iamge_view_modal"
        isOpen={openImageView}
        toggle={toggleImageView}
        fullscreen={true}
      >
        <GalleryView />
      </Modal>
    </>
  );
}

export default Product;
