import React, { useState, useContext, useEffect } from "react";
import "./galleryCarousel.css";
import { Row } from "reactstrap";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

//context
import { AppContext } from "../../contexts/AppContext";
const MainCarousel = (props) => {
  //context
  const { toggleGallery } = useContext(AppContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [scaleImage, setScaleImage] = useState(1);
  const [playImage, setPlayImage] = useState(false);
  const handleNavClick = (index) => {
    setCurrentSlide(index);
  };

  const zoomHandler = (size) => {
    //future use
    setScaleImage((curr) => curr + size);
  };

  const handleAutoScroll = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % props?.images.length);
  };

  useEffect(() => {
    if (playImage) {
      const interval = setInterval(handleAutoScroll, 3000); // Reduced the time for demonstration purposes
      return () => clearInterval(interval);
    }
  }, [playImage]);

  return (
    <div id="gallery_view">
      <Row className="imageviewer_header pt-3">
        <span
          className="icon_wrapper_viewr"
          onClick={() => setPlayImage(!playImage)}
        >
          {!playImage ? (
            <PlayArrowIcon htmlColor="white" />
          ) : (
            <PauseIcon htmlColor="white" />
          )}
        </span>
        <span className="icon_wrapper_viewr">
          <CloseIcon onClick={toggleGallery} htmlColor="white" />
        </span>
      </Row>
      <div className="slider-container-imageviewer">
        <div className="slider">
          <span className="left_arrow_gall">
            <ChevronLeftIcon
              onClick={() => {
                if (currentSlide !== 0) {
                  handleNavClick(currentSlide - 1);
                }
              }}
            />
          </span>
          {props?.images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
              style={{
                backgroundImage: `url(${image})`,
                transform: `translateX(-${currentSlide * 100}%)`, // Move to the current slide
              }}
            />
          ))}
        </div>
        <div className="dot-img-container">
          {props?.images.map((image, index) => (
            <div
              key={image + "jhvhjs"}
              className={`dot-img mx-2 text-center ${
                index === currentSlide ? "dot-img-active mx-2 text-center" : ""
              }`}
            >
              <img
                onClick={() => handleNavClick(index)}
                width="120px"
                height={"80px"}
                src={image}
                alt="nav"
              />
              {/* <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleNavClick(index)}
            /> */}
            </div>
          ))}
        </div>
        <span className="right_arrow_gall">
          <ChevronRightIcon
            onClick={() => {
              if (currentSlide < props?.images?.length - 1) {
                handleNavClick(currentSlide + 1);
              }
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default MainCarousel;
