import React from "react";
import { Col, Row } from "reactstrap";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";

function BestSellingCard(props) {
  return (
    <Col className="d-flex justify-content-center px-2 py-2">
      <Link to={`/tour/${props?.name?.split(" ").join("-").toLowerCase()}`}>
        <div className="best-selling-card">
          {props?.tag === true && (
            <div className="ribbon-wrapper">
              <div className="ribbon">{props?.tagname}</div>
            </div>
          )}
          <div className="card-content">
            <LazyLoadImage
              effect="blur"
              width="100%"
              src={props.src}
              alt="image"
              style={{
                objectFit: "cover",
                width: "500px",
                height: "400px",
              }}
            />
          </div>

          <div className="overlay-text-best-selling">
            <h6 className="best-selling-card-title truncate">
              {props?.name ?? "Memorable Mumbai"}
            </h6>
            <p className="best-selling-sub-para truncate">
              <AccessTimeIcon fontSize="small" /> {props?.duration}
            </p>
            <p className="best-selling-sub-title truncate">
              <GroupsIcon fontSize="small" /> Max people: {props?.MaxPeople}
            </p>{" "}
            <p className="best-selling-sub-title truncate">
              <PersonOutlineOutlinedIcon fontSize="small" /> Availablity:{" "}
              {props?.Availability}
            </p>
            <hr className="my-1" />
            <Row className="justify-content-center pt-1 align-items-center">
              <Col>
                <h6 className="truncate m-0 bestselling_price">
                  {props?.price ?? "₹500"}
                </h6>
              </Col>
              <Col className="text-end">
                <button className="buy_now_btn">VIEW NOW</button>
              </Col>
            </Row>
          </div>
        </div>
      </Link>
    </Col>
  );
}
export default BestSellingCard;
