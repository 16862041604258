import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import CollectionCard from "../Collections/CollectionCard";
//Context
import { AppContext } from "../../contexts/AppContext";
import blogs from "../../Config/bogsConfig.json";

import { useLocation } from "react-router-dom";
import BlogCard from "./BlogCard";
function BlogCollection() {
  //Context
  const { blogCollection, setBlogCollection } = useContext(AppContext);
  let location = useLocation();

  useEffect(() => {
    if (window.location.pathname === "/blogs") {
      setBlogCollection(blogs.Blogs);
      console.log("activeCollection: ", blogCollection);
    }
  }, [location?.pathname, setBlogCollection]);

  return (
    <>
      <div className="collection_wrapper pt-5 mt-5 bg-light">
        <Col>
          <h3 className="home-title">All Blogs</h3>
        </Col>
        <Row className="mt-3 px-3">
          {blogCollection?.length > 0 ? (
            blogCollection.map((blogs) => (
              <Col md="3" xs="12">
                <BlogCard Blog={blogs} />
              </Col>
            ))
          ) : (
            <Row className="text-center py-5 my-5 collection-card-title">
              <h4>No blogs</h4>
            </Row>
          )}
        </Row>
      </div>
    </>
  );
}

export default BlogCollection;
