import React from "react";
import { Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./collection.css";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link } from "react-router-dom";

function CollectionCard({ props, activeCollection }) {
  console.log("props: ", activeCollection);

  return (
    <>
      {activeCollection?.map((item) => (
        <Col
          lg="3"
          md="6"
          sm="6"
          xs="12"
          className="d-flex justify-content-center px-2 py-2"
        >
          <Link to={`/tour/${item?.name?.split(" ").join("-").toLowerCase()}`}>
            <div className="best-selling-card">
              {props?.tag === true && (
                <div className="ribbon-wrapper">
                  <div className="ribbon">
                    {item?.name ?? "Memorable Mumbai"}
                  </div>
                </div>
              )}
              <div className="card-content">
                <LazyLoadImage
                  effect="blur"
                  width="100%"
                  src={item?.images[0]}
                  alt="image"
                  style={{
                    objectFit: "cover",
                    // width: "500px",
                    // height: "400px",
                  }}
                />
              </div>

              <div className="overlay-text-best-selling">
                <h6 className="best-selling-card-title truncate">
                  {item?.name ?? "Memorable Mumbai"}
                </h6>
                <p className="best-selling-sub-para truncate">
                  <AccessTimeIcon fontSize="small" /> {item?.duration}
                </p>
                <p className="best-selling-sub-title truncate">
                  <GroupsIcon fontSize="small" /> Max people: {item?.MaxPeople}
                </p>{" "}
                <p className="best-selling-sub-title truncate">
                  <PersonOutlineOutlinedIcon fontSize="small" /> Availablity:{" "}
                  {item?.Availability}
                </p>
                <hr className="my-1" />
                <Row className="justify-content-center pt-1 align-items-center">
                  <Col>
                    <p className="best-selling-sub-para truncate">{"From"}</p>
                    <h6 className="truncate m-0 bestselling_price">
                      {item?.offerPrice ?? "₹500"}
                    </h6>
                  </Col>
                  <Col className="text-end">
                    <button className="buy_now_btn">VIEW NOW</button>
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </>
  );
}

export default CollectionCard;
