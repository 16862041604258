import React, { useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./bestsellingcard.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../index.css";
import products from "../../Config/configuration.json";
import BestSellingCard from "./BestSellingCard";
import { Link } from "react-router-dom";

import { AppContext } from "../../contexts/AppContext";
const BestSelling = () => {
  const { bestSellingTours, setBestSellingTours } = useContext(AppContext);

  useEffect(() => {
    const allProducts = products?.Collections.filter(
      (products) => products?.["best-selling"] === true
    );
    setBestSellingTours(allProducts);
  }, [setBestSellingTours]);
  return (
    <>
      <Row className="container m-auto mt-4">
        <Col lg="12">
          <Row className="py-2">
            <Col>
              <h3 className="home-title">
                BEST-SELLING TOURS
                <span className="dot_icon">.</span>
              </h3>
            </Col>
            <Col className="text-align-end desktop_only">
              <div className="d-flex w-100 justify-content-end ">
                <Link
                  className="decoration-none d-flex w-100 justify-content-end text-dark"
                  to={`/collection/best-selling`}
                >
                  <h4 className="home-subtitle cursor-pointer">All</h4>
                  <ArrowForwardIcon className="cursor-pointer" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="best-selling-wrapper">
            {bestSellingTours?.map((item) => (
              <BestSellingCard
                name={item?.name}
                subtitle={item?.name}
                price={item?.offerPrice}
                duration={item?.duration}
                MaxPeople={item?.MaxPeople}
                Availability={item?.Availability}
                src={item?.images[0]}
              />
            ))}
          </Row>
          <Col className="mob_only py-2">
            <div className="d-flex w-100 justify-content-center ">
              <Link
                className="decoration-none d-flex w-100 justify-content-center text-dark"
                to={`/collection/best-selling`}
              >
                <h4 className="home-subtitle cursor-pointer">All</h4>
                <ArrowForwardIcon className="cursor-pointer" />
              </Link>
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BestSelling;
