import React from "react";
import { Row } from "reactstrap";
import "./whatsapp.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const Whatsapp = (props) => {
  const phoneNumber = "8210773776"; // Replace with the desired phone number
  const message = "Hello, I want to chat!"; // Replace with the desired message

  // Construct the WhatsApp link
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <>
      <a
        className="refLinks"
        href={"https://wa.me/+91" + 8210773776}
        target="_blank"
        rel="noreferrer"
      >
        <Row className="whatsapp_wrapper">
          <WhatsAppIcon
            sx={{
              fontSize: "3.3rem",
              color: "black",
            }}
          />
        </Row>
      </a>
    </>
  );
};

export default Whatsapp;
