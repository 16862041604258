import React, { useState } from "react";

export const AppContext = React.createContext();
const AppContextProvider = (props) => {
  const [isOpenDrawer, setIsOpenDrwaer] = useState(false);
  const [collectionName, setCollectionName] = useState("collection");
  const [activeCollection, setActiveCollection] = useState([]);
  const [blogCollection, setBlogCollection] = useState([]);

  const [featuredTours, setFeaturedTours] = useState([]);
  const [bestSellingTours, setBestSellingTours] = useState([]);
  const [topSightSeeingTours, setTopSightSeeingTours] = useState([]);
  const [activePolicy, setActivePolicy] = useState("Terms and Conditions");

  const [activeProduct, setActiveProduct] = useState();

  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [openImageView, setOpenImageView] = useState(false);

  const handleDrawer = () => {
    setIsOpenDrwaer(!isOpenDrawer);
  };
  const toggleImageView = () => {
    setOpenImageView(!openImageView);
  };
  const toggleGallery = () => {
    setIsOpenGallery(!isOpenGallery);
  };

  function openFullscreen(id) {
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  return (
    <AppContext.Provider
      value={{
        isOpenDrawer,
        setIsOpenDrwaer,
        handleDrawer,
        collectionName,
        setCollectionName,
        activeCollection,
        setActiveCollection,
        isOpenGallery,
        setIsOpenGallery,
        toggleImageView,
        openImageView,
        setOpenImageView,
        toggleGallery,
        openFullscreen,
        activeProduct,
        setActiveProduct,
        featuredTours,
        setFeaturedTours,
        bestSellingTours,
        setBestSellingTours,
        topSightSeeingTours,
        setTopSightSeeingTours,
        activePolicy,
        setActivePolicy,
        blogCollection,
        setBlogCollection,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppContextProvider;
