import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Modal } from "reactstrap";
import ProductCard from "../Products/ProductCard";
import { AppContext } from "../../contexts/AppContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GalleryView from "../Image Viewer/GalleryView";
import { useLocation } from "react-router-dom";
import blogs from "../../Config/bogsConfig.json";
import YouTube from "react-youtube";
import Accordion from "../Accordian/Accordion";
import FaqAccordion from "../Accordian/FaqAccordion";
// import GoogleMapProduct from "../Map/GoogleMapProduct"
function BlogView() {
  //context
  const { openImageView, toggleImageView, activeProduct, setActiveProduct } =
    useContext(AppContext);
  console.log("activeProduct: ", activeProduct);

  let location = useLocation();

  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth > 1024 ? true : false
  );

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (window.location.pathname.includes("/blog/")) {
      const productName = window.location.pathname.toLocaleLowerCase();

      // Use the .find() method to find the product by name
      const foundProduct = blogs?.Blogs.find(
        (product) => product.link === productName
      );

      setActiveProduct(foundProduct);
      console.log("foundProduct: ", foundProduct);
    }
  }, [location?.pathname]);

  return (
    <>
      <div className="container mt-5 pt-5">
        {activeProduct?.title?.length > 0 ? (
          <Col className="p-0 d-flex col align-items-center">
            <h3 className="home-title">{activeProduct?.title}</h3>
            <div
              className="pt-2"
              dangerouslySetInnerHTML={{
                __html: activeProduct?.properties?.content?.value,
              }}
            />
          </Col>
        ) : (
          <Row className="text-center py-5 my-5 collection-card-title">
            <h4>No such blog</h4>
          </Row>
        )}
      </div>
    </>
  );
}

export default BlogView;
