import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import "../../Pages/Header/header.css";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
export default function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="menu_button">
      <p
        className={
          props?.button !== "true"
            ? "menu_button_li m-0"
            : "menu_button_li_box m-0"
        }
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {props?.name}
        {/* {props?.button === "true" && <BackpackIcon htmlColor="black" />} */}
        {props?.submenu?.length > 0 && (
          <ArrowDropDownOutlinedIcon htmlColor="black" />
        )}
      </p>
      {props?.submenu?.length > 0 ? (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {props?.submenu?.map((menu, i) => {
            return (
              <MenuItem key={menu} onClick={handleClose}>
                {menu}
              </MenuItem>
            );
          })}
        </Menu>
      ) : (
        ""
      )}
    </div>
  );
}
