import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./accordion.css";
export default function AccordionMui({ highlights, heading ,policies,setActivePolicy}) {
  const [expanded, setExpanded] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // Default active section

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleMenuClick = (sectionId) => {
    // Update the active section when a menu item is clicked
    setActiveSection(sectionId);

    // Scroll to the selected section
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="pb-5">
      <h5 className="prdct_subheader pb-3">{heading ?? ""}</h5>
      {highlights?.length > 0 &&
        highlights?.map((item, i) => (
          <Accordion
            className="accordian_header"
            expanded={expanded === i}
            onChange={handleChange(i)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <Typography
                className="accordian_title"
                sx={{ width: "100%", flexShrink: 0 }}
                onClick={()=>{
                  if(policies === true ){
                    setActivePolicy(item?.name)
                  }
                }}
              >
                {item?.name}
              </Typography>
            </AccordionSummary>
            {policies === true ? item?.Description?.map((item,i)=>(
               <AccordionDetails key={item?.text +i} className="accordian_body">
              <Typography className="accordian_subtitle_policies"
              onClick={()=>{
                handleMenuClick(i)
              }}>
                {item?.text}
              </Typography>
            </AccordionDetails>
            ))
            :
            <AccordionDetails className="accordian_body">
            <Typography className="accordian_subtitle">
              {item?.Description}
            </Typography>
          </AccordionDetails>
          }
          </Accordion>
        ))}
    </div>
  );
}
